@import '../../bento-styles.module';
@import '../../../../styles/dimensions';
@import '../../../../styles/breakpoints';
@import '../../../../styles/colors';

.boxOne {
    @extend %box;
    background-color: palette-var('dark-grey');
}

.boxOneContent {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: $gap-60px $gap-medium-large;
    position: relative;
    width: 100%;

    @media only screen and (max-width: 667px) {
        padding: $gap-medium-large $gap-small;
    }
}

.separator {
    background-color: palette-var('white');
    height: 1px;
    margin: 16px 0;
    width: 100%;
}

.separatorThick {
    background-color: palette-var('white');
    height: 9px;
    margin: 16px 0;
    width: 100%;
}

.title {
    font-size: 42px;
    font-weight: 900;
    margin: 0;

    @media only screen and (max-width: 667px) {
        font-size: 28px;
    }
}

.markdownSection {
    h5 {
        font-size: 18px;
        margin: 0 0 2px;
    }

    p {
        margin: 4px 0 0;
    }

    .sectionTitle {
        margin: 0 0 8px;
    }
}

.dataContainer {
    display: flex;
    justify-content: space-between;
    padding: 0 0 4px;

    .dataValue {
        font-weight: 600;
    }

    @media only screen and (max-width: 667px) {
        .dataValue,
        .dataEntry {
            font-size: 14px;
        }
    }
}

.colorPink {
    color: palette-var('future-fuscia');
}
